<template>
  <div>
    <el-row>
      <el-col v-if="!data.properties.hideLabel" :span="24">
        <label
          for="text"
          v-if="hasLabel && !(data.properties && data.properties.hideLabel)"
          :style="getLabelStyles"
        >
          {{ label || data.label }}
        </label>
        <label for="text" :span="24">
          {{ data.label }}
        </label>
        <span
          v-if="data.validations && data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
      </el-col>
    </el-row>
    <div
      v-html="data.content"
      :style="getStyle"
      v-if="data.properties.filed_content != 'Hide'"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "templates-formComponentsExecute-HtmlExecute",
  props: ["data", "label", "fromEntityViews"],
  data() {
    return {
      hasLabel: false,
    };
  },
  mounted() {},
  computed: {
    ...mapGetters("navigationOpen", ["getIsMobile"]),
    getStyle() {
      if (this.getIsMobile) {
        return this.getElementStyle;
      }
      return (
        `height:${this.data.height}px;width:${this.data.width}px;overflow:auto;` +
        this.getElementStyle
      );
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";

        borderStyle = "border:" + type + " " + " " + size + " " + color;

        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
  },
};
</script>

<style lang="scss">
.resposive-font {
  font-size: 1.2vw;
  line-height: 1.5;
}

.transparentcol {
  color: white;
  display: inline-block;
  height: 20px;
  opacity: 0;
}
</style>
